import React from "react";
import Layout from "../components/layout";

export default function NotFound(pathObj) {
	if (typeof window !== "undefined") {
		if (pathObj.location.pathname.indexOf('/blog/') === 0) {
			window.location = "/blog"
			return null
		} else {
			return (
				<Layout>
					<div className="container">
						<br/>
						<p className="error-code">404</p>
						<p className="error-text">Page you are looking for is not found.</p>
					</div>
				</Layout>
			);
		}
	}
	return null
}
